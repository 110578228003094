import React from 'react'

import Page from 'src/templates/page'
import Appointment from 'src/templates/appointment'
import Service from 'src/templates/service'
import Editorial from 'src/templates/editorial'
import Team from 'src/templates/team'
import Location from 'src/templates/location'

const queries = require('src/api/sanity/queries')

const sanityClient = require('@sanity/client')

// FIXME: why can't we access process env here again 🤔
const client = sanityClient({
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_DATASET,
  useCdn: false, // `false` i,f you want to ensure fresh data
  withCredentials: true,
})

// FIXME: Update this component to be TS-y

class PreviewPage extends React.Component<
  { path: string; document?: Document },
  { document: null | Document; type: null | string; doc: any; loaded: boolean }
> {
  constructor(
    props: Readonly<{ path: string; document?: Document | undefined }>
  ) {
    super(props)
    this.state = {
      document: null,
      type: null,
      doc: {},
      loaded: false,
    }
  }

  public componentDidMount() {
    const docId = new URLSearchParams(this.props.location.search).get('id')
    // @ts-ignore
    const queryDraft = `*[_id == "${docId}"]  {
      ...,
    }`

    // @ts-ignore
    const queryPreviewPage = `*[_id == "${docId}"]  {
      ${queries.pageQuery}
    }`

    // @ts-ignore
    const queryPreviewAppointment = `*[_id == "${docId}"]  {
      ${queries.appointmentQuery}
    }`

    // @ts-ignore
    const queryPreviewService = `*[_id == "${docId}"]  {
      ${queries.serviceQuery}
    }`

    // @ts-ignore
    const queryPreviewEditorial = `*[_id == "${docId}"]  {
      ${queries.editorialQuery}
    }`

    // @ts-ignore
    const queryPreviewTeam = `*[_id == "${docId}"]  {
      ${queries.teamQuery}
    }`

    // @ts-ignore
    const queryLocationTeam = `*[_id == "${docId}"]  {
      ${queries.locationQuery}
    }`

    client
      .fetch(queryDraft)
      // @ts-ignore
      .then(response => {
        this.setState({
          type: response[0]?._type,
        })
        switch (response[0]?._type) {
          case 'page':
            // @ts-ignore
            client.fetch(queryPreviewPage).then(res => {
              this.setState({
                doc: res[0],
                loaded: true,
              })
            })
            break
          case 'join':
            // @ts-ignore
            client.fetch(queryPreviewPage).then(res => {
              this.setState({
                doc: res[0],
                loaded: true,
              })
            })
            break
          case 'appointment':
            // @ts-ignore
            client.fetch(queryPreviewAppointment).then(res => {
              this.setState({
                doc: res[0],
                loaded: true,
              })
            })
            break
          case 'service':
            // @ts-ignore
            client.fetch(queryPreviewService).then(res => {
              this.setState({
                doc: res[0],
                loaded: true,
              })
            })
            break
          case 'editorial':
            // @ts-ignore
            client.fetch(queryPreviewEditorial).then(res => {
              this.setState({
                doc: res[0],
                loaded: true,
              })
            })
            break
          case 'team':
            // @ts-ignore
            client.fetch(queryPreviewTeam).then(res => {
              this.setState({
                doc: res[0],
                loaded: true,
              })
            })
            break
          case 'location':
            // @ts-ignore
            client.fetch(queryLocationTeam).then(res => {
              console.log('location', res[0])
              this.setState({
                doc: res[0],
                loaded: true,
              })
            })
            break
          default:
            return
        }
      })
      // @ts-ignore
      .catch(error => {
        console.error('problem', error)
      })
  }

  public renderPreview() {
    // @ts-ignore
    if (this.state.loaded) {
      // @ts-ignore
      switch (this.state.type) {
        case 'page':
          return <Page pageContext={this.state.doc.content} preview={true} />
        case 'appointment':
          return (
            <Appointment pageContext={this.state.doc.content} preview={true} />
          )
        case 'service':
          return <Service pageContext={this.state.doc.content} preview={true} />
        case 'editorial':
          return (
            <Editorial pageContext={this.state.doc.content} preview={true} />
          )
        case 'join':
          return <Page pageContext={this.state.doc.content} preview={true} />
        case 'team':
          return <Team pageContext={this.state.doc.content} preview={true} />
        case 'location':
          return (
            <Location pageContext={this.state.doc.content} preview={true} />
          )
        default:
          break
      }
    }
  }

  public render() {
    return <div>{this.renderPreview()}</div>
  }
}

const Previews = props => {
  return (
    <div>
      <div className="x p1 pys ac bcraspberry">
        <span className="caps mono">This is a preview page</span>
      </div>
      <PreviewPage location={props.location} />
    </div>
  )
}

export default Previews
